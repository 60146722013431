var site = site || {};
site.validatePassword = site.validatePassword || {};
(function($) {
  var rulesObj;
  Drupal.behaviors.passwordTooltipIndicatorV1 = {
    attach: function(context) {
      site.validatePassword.passwordTooltip.attach(context);
    },
  };
  site.validatePassword.passwordTooltip = {
    attach: function(context) {
      rulesObj = this;
      rulesObj.context = context;
      rulesObj.initPwdValidationTooltip();
      rulesObj.rules = {
        'contains_lower_case_chars': /[a-z]/,
        'contains_uppercase_chars': /[A-Z]/,
        'contains_numbers': /[0-9]/,
        'minimum_length': /.{8,}/
      };
    },
    validatePwdRules: function(evt) {
      var $input = $(this);
      var value = this.value;
      var $rulesItems = evt.data.find('ul.js-password-field__rules > li');
      evt.data.removeClass('hidden');
      $input.toggleClass('hasValue', this.value);
      $.each($rulesItems, function(i, elm) {
        var valid;
        var $elem = $(elm);
        var ruleName = $elem.data('rule');
        if (rulesObj.rules[ruleName]) {
          valid = rulesObj.rules[ruleName].test(value);
          if (ruleName === 'contains_special_character' && valid && (/[|_]/u).test(value)) {
            valid = !valid;
          }
          $elem.toggleClass('pass', valid);
        }
      });
    },

    initPwdValidationTooltip: function() {
      var $pwdContainer = $('.js-password-field', this.context);
      var $pwdInfo = $('.js-password-field__info', $pwdContainer);
      var $pwdField = $('input[type="text"],input[type="password"]', $pwdContainer);
      $pwdField.on('keyup touchend focus', $pwdInfo, this.validatePwdRules);
      $pwdField.on('blur', function() {
        $pwdInfo.addClass('hidden');
      });
    }
  };
})(jQuery);
